.grid-contact {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin: 20px;
  align-items: center; /* Vertical alignment */
  justify-items: center; /* Horizontal alignment */
  /* font-family: "Montserrat", sans-serif; */
}

.contact-item {
  background-color: rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-item img {
  width: 300px;
  height: auto;
}
.contact-vertical {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;
  width: 80%;
}
.contact-vertical iframe {
  height: 400px;
}
.contact-horizontal {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
}
.contact-horizontal p {
  margin-bottom: 0;
}
.contact-horizontal img {
  width: 30px;
  height: 30px;
}

@media (max-width: 992px) {
  .grid-contact {
    width: 100%;
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0px;
  }
  .contact-vertical {
    width: 80%;
  }
  .contact-item img {
    width: 100%; /* Set image width to 100% to make it responsive */
  }
  .contact-horizontal {
    width: 100%;
  }
}
