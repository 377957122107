.grid-galery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 3fr));
  gap: 20px;
  margin: 20px;
  align-items: center; /* Vertical alignment */
  justify-items: center; /* Horizontal alignment */
}

.galery-item {
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* padding: 20px; */
  width: 300px;
  height: fit-content;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 8px;
}

.galery-item img {
  width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
}
.galery-item :hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .galery-item img {
    width: 100%; /* Set image width to 100% to make it responsive */
    border-radius: 4px;
  }
}
