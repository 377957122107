.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  gap: 20px;
  margin: 20px;
}

.item {
  background-color: rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: opacity 1s ease-in-out;
}
.item-span {
  position: absolute;
  font-size: x-large;
  bottom: 0;
}
.item-span.black {
  width: 100%;
  height: 100%;
  color: #b0b0b0;
  background-color: rgba(0, 0, 0, 0.7);
}
.item-span.inactive {
  display: none;
}
.paragraph-center {
  position: absolute;
  width: 100%;
  bottom: 10%;
}
.paragraph-center.inactive {
  display: none;
}
.paragraph-center.small {
  font-size: x-large;
}
.item img {
  width: 100%;
  max-height: 100vh;
  height: auto;
  transition: opacity 1s ease-in-out;
  display: none;
}
.item img.active {
  opacity: 1;
  display: block;
}
.wrap {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  align-items: center;
}
.wrap.wide {
  width: 100%;
}
.wrap.vertical {
  flex-direction: column;
  color: #fff;
}
.wrap.vertical:hover {
  cursor: pointer;
}
.wrap.vertical img {
  height: 50px;
}
.icon-span {
  margin-top: 5px;
  font-size: small;
}

@media (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    margin: 0px;
  }

  .item img {
    width: 100%; /* Set image width to 100% to make it responsive */
  }
  .item-paragraph {
    font-size: large;
  }
  .paragraph-center.small {
    font-size: medium;
  }
  .icon-span {
    margin-top: 5px;
    font-size: x-small;
  }
}
