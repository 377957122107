/*
	Landed by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Loader */

	body.landing.is-preload:before {
		display: none;
	}

	body.landing.is-preload:after {
		display: none;
	}