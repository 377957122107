/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}
.modal-content img {
  max-width: 600px;
  max-height: 600px;
  overflow-y: auto;
}

.modal-close {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 991px) {
  .modal-content img {
    max-width: 90vw;
    max-height: 90vh;
  }
  .modal-content {
    max-width: 95%;
    max-height: 95%;
    padding: 10px;
  }
}
