.App {
  color: #d8d8d8;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  /* #88A496 */
}

.layout-wrap {
  display: flex;
  flex-grow: 1; /* Allow the content container to grow */
  margin: 0; /* Center the content horizontally */

  overflow-y: auto; /* Allow vertical scrolling when content overflows */
  justify-content: center;
}
/* Custom styles for WebKit scrollbars */
.layout-wrap::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.layout-wrap::-webkit-scrollbar-thumb {
  background-color: #88a496; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}

.layout-wrap::-webkit-scrollbar-thumb:hover {
  background-color: #6d8378; /* Color of the thumb on hover */
}

.layout-wrap::-webkit-scrollbar-track {
  background-color: #24293c; /* Color of the track */
}
@media (max-width: 991px) {
  .layout-wrap {
    overflow: auto;
    padding: 0;
  }
  .App {
    flex-direction: column;
  }
}
