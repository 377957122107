.App {
  color: #d8d8d8;
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* logo green: #88A496 */
}
@media only screen and (min-width: 992px) {
  .mobile {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .desktop {
    display: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
