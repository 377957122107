.grid-about {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px;
  align-items: center; /* Vertical alignment */
  justify-items: center; /* Horizontal alignment */
}

.about-item {
  /* padding: 20px; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-item img {
  width: 400px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
}

button {
  border-radius: 15px;
  background-color: #88a496;
  color: #d8d8d8;
  /* font-family: "Montserrat", sans-serif; */
  font-size: large;
  width: 160px;
  height: 50px;
}

@media (max-width: 992px) {
  .grid-about {
    grid-template-columns: 1fr;
    margin: 0px;
    padding: 5px;
    background-image: none;
  }

  .about-item img {
    width: 100%; /* Set image width to 100% to make it responsive */
  }
}
