@media only screen and (min-width: 992px) {
  .nav-wrap {
    display: flex;
    flex-direction: column;
    flex-basis: 180px;
    align-self: stretch;
    align-items: stretch;
    background-color: #24293c;
    margin-right: 0;
  }
  .logo-wrap {
    width: 180px;
    border-bottom: 2px solid #1e2131;
  }
  .logo-wrap img {
    width: 100%;
  }
  .menu-item {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: center;
    align-items: center;

    border-bottom: 2px solid #1e2131;
  }
  .menu-item span {
    height: 2rem;
  }
  .menu-item:hover {
    cursor: pointer;
    background-color: #1e2131;
    transition: 0.4s;
  }
  .menu-item.active {
    background-color: #1e2131;
  }
}
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 180;
  width: 70%;
  background-color: #24293c; /* Change to your desired background color */
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.bottom-nav-link {
  text-decoration: none;
  color: #fff; /* Change to your desired text color */
}
.bottom-nav-link.active {
  color: #88a496; /* Change to your desired text color */
}

@media only screen and (max-width: 991px) {
  .nav-wrap {
    display: flex;
    flex-direction: row;
    /* flex-basis: 100vw; */
    width: 100vw;
    justify-content: space-between;
    padding: 8px;
    align-self: flex-start;
    align-items: center;
    background-color: #24293c;
    border-bottom: 2px solid #1e2131;
    box-sizing: none;
  }
  .bottom-navbar {
    left: 0;
    width: 100%;
    background-color: #24293c; /* Change to your desired background color */
    display: flex;
    justify-content: space-around;
    padding: 15px;
  }
  .bottom-nav-link {
    text-decoration: none;
    color: #fff; /* Change to your desired text color */
  }
  .logo-wrap {
    width: 50px;
  }
  .logo-wrap img {
    width: 100%;
  }
  .menu-item {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: center;
    align-items: center;

    border-bottom: 2px solid #1e2131;
  }
  .menu-item span {
    height: 2rem;
  }
  .menu-item:hover {
    cursor: pointer;
    background-color: #1e2131;
    transition: 0.4s;
  }
  .menu-item.active {
    background-color: #1e2131;
  }
  .menu-icon {
    display: flex;
    align-self: stretch;
  }
  .menu-icon:hover {
    cursor: pointer;
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    position: absolute;
    top: 91px;
    right: -320px; /* Initially position the menu off the screen to the right */
    width: 300px;
    height: fit-content;
    background-color: #24293c;
    transition: right 0.3s ease-in-out;
  }
  .mobile-menu.closed {
    display: none;
  }

  .mobile-menu.open {
    right: 0; /* Slide the menu in when it's open */
    z-index: 9999;
  }
}
