html,
#root {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  height: 100%;
  /* font-family: "Montserrat", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e2131;
  color: #fffaf8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
